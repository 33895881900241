.ProseMirror-focused {
  outline: none !important;
}

/* 
.ProseMirror {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.ProseMirror:not(.ProseMirror-focused) {
  margin: 2px;
}

.ProseMirror:hover:not(.ProseMirror-focused) {
  border: 2px solid rgba(232, 75, 250, 0.25);
  margin: 0px;
} */
